h2 {
  margin-bottom: 5rem;
}

.map__container {
    padding: 5vh;
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 5%;
  }

.map {
  height: 90vh; 
  width: 100%; 
  overflow: hidden;
}

  @media screen and (max-width: 1024px){
    .map__container {
      padding: 1vh;
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    .map {
      height: 75vh; 
    }
  }

  @media screen and (max-width: 600px){
    .map {
      height: 50vh; 
    }
  }